@import 'base';
@import 'variables';
@import 'mixins';
@import 'perstare-fonts';

// General fixes
html,
body {
    margin: 0;
    padding: 0;
}

.bypass-nav, .bypass-content { 
    font-family: 'NYUPerstare', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    background: #3dbbdb;
    color: #000;
    height: 3rem;
    left: -3rem;
    line-height: 3rem;
    padding: 0 3rem 0 6rem;
    position: absolute;
    text-transform: uppercase;
    top: 3.75rem;
    z-index: 500;
    -webkit-transition: all 300ms cubic-bezier(0.3, -0.01, 0.57, 1.36);
    transition: all 300ms cubic-bezier(0.3, -0.01, 0.57, 1.36);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    display: none;

    	&:focus{
		transform: translateX(0);
	}
}

*[id^='GN-'], *[class^='GN-'] {
    * {
        &:focus {
            outline: none;
            -webkit-box-shadow: inset 0 0 0 3px $color-tab-focus;
            box-shadow: inset 0 0 0 3px $color-tab-focus;
            opacity: 1 !important;
        }
    }
}

.GN-full-width {
    #GN-banner {
        width: 100% !important;
    }

    .GN-logo {
        a {
            display: flex;
            height: 60px;
            position: relative;
            line-height: 60px;
            padding-left: 15px;
            padding-right: 15px;

            img {
                margin: auto;
            }
        }
    }
}

.GN-no-touch {
    #GN-logo:hover,
    #GN-toggle-local-nav:hover,
    #GN-toggle-global-nav:hover {
        background-color: transparent !important;
    }
}

// Text meant only for screen readers
.GN-screen-reader-text {
    border: 0;
    clip: rect( 1px, 1px, 1px, 1px );
    clip-path: inset( 50% );
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
}

#GN-overlay {
    @include transition(opacity, $time-quantum);

    background-color: $color-overlay-mask;
    height: 100% !important;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 60px;
    width: 100% !important;
    z-index: -30;
}

// Base styles
#GN-sub-nav {
    @include font-perstare("NYUPerstare", 400);

    background-color: $color-nyu-purple;
    max-width: 100%;
    position: relative;
    width: inherit;
    z-index: 9999;

    img {
        display: inline-block;
        vertical-align: middle;
    }

    button {
        border:0;
        background:none;
        cursor: pointer;
        padding: 0;
    }

    #GN-banner {
        margin: 0 auto;
        position: relative;
        z-index: 30;

        .GN-icons-layer {
            @include transitions(left 1s);
            background-color: $color-nyu-purple;
            display: flex;
            justify-content:flex-start;
            left:0;
            position: relative;
            z-index: 30;

            .GN-logo, .GN-toggle-local-nav, #GN-toggle-search-box, #GN-toggle-global-nav {
                @include transition(background-color, 0.3s);

                color: $color-white;
                height: 60px;
                position: relative;
                line-height: 60px;
                padding-left:15px;
                padding-right:15px;

                svg {
                    fill: $color-white;
                    vertical-align: middle;
                }

                &::after {
                    @include transition(opacity, 0.3s);

                    background: $color-white;
                    bottom: 0;
                    content: "";
                    height: 4px;
                    left:0;
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                }

                &:hover {
                    background-color: $color-nyu-darker-purple;

                    &::after {
                        opacity: 1;
                    }
                }
            }

            .GN-toggle-local-nav {
                line-height: 50px;
                border: 0;
                background: none;

                // Override to always show card stack
                &.permanent {
                    display: block !important;
                }

                &.active {
                    .GN-nav-bars {
                        top: 9px;
                        transition-delay: .22s;
                        transition-timing-function: cubic-bezier(.215,.61,.355,1);
                        transform: translate3d(0,-10px,0) rotate(-45deg);

                        &::before {
                            top: 0;
                            transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s,transform .13s cubic-bezier(.215,.61,.355,1) .25s;
                            transform: rotate(-90deg);
                        }

                        &::after {
                            top: 0;
                            transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s linear .22s;
                            opacity: 0;
                        }
                    }
                }

                .GN-nav-bars {
                    background: $color-white;
                    height: 4px;
                    line-height: 60px;
                    position: relative;
                    width: 22px;

                    &::before, &::after {
                        transition: all .2s;
                        position: absolute;
                        content: "";
                        background:  $color-white;
                        height: 4px;
                        width: 22px;
                        left:0;
                        top:-9px;
                    }

                    &::after {
                        top:9px;
                    }
                }
            }

            .GN-logo {
                margin-left: -15px;
                margin-right: auto;
                line-height: 59px;
                display: flex;
                align-items: center;

                .GN-logo-full {
                    display: none;
                }
            }

            .GN-icons-right {
                margin-left: auto;

                & > div, & > button {
                    background-repeat: no-repeat;
                    background-position: center;
                    display: inline-block;
                    margin-left: 1px;
                    position: relative;

                    &::before {
                        background: #fff;
                        content: "";
                        height: 30px;
                        position: absolute;
                        right: -1px;
                        top: 15px;
                        width: 1px;
                    }

                    &:last-child {
                        margin-right: 0;

                        &::before {
                            content: none;
                        }
                    }
                }

                #GN-search {
                    padding-left: 15px;
                    padding-right: 15px;
                    display: none;

                    &::before {
                        top: 4px;
                    }

                    .GN-search-text-field-container-desktop {
                        position: relative;

                        .GN-search-btn {
                            top: 11px;
                        }
                    }
                }

                #GN-toggle-global-nav {
                    @include font-perstare("NYUPerstare", 400);

                    font-size: .75em;
                    line-height: 50px;

                    .GN-btn-txt {
                        display: none;
                    }
                }
            }
        }

        input {
            @include transition(background-color, 350ms);
            @include font-perstare("NYUPerstare", 400);

            background-color: $color-nyu-purple;
            border: 0;
            border-bottom: 1px solid white;
            border-radius: 0;
            color: #b8b8b8;
            font-size: 0.95em;
            letter-spacing: 1px;
            line-height: 24px;
            padding: 6px 25px 0 5px;
            width: 228px;
            text-indent: 8px;

            @include placeholder {
                color: $color-lavender;
                font-size: 0.688em;
                left: -5px;
                opacity: 1;
                position: relative;
            }

            /* When encountering an unsupported vendor prefix,
            * CSS parsing engines will consider the entire rule invalid,
            * which is why a separate rule set for each vendor prefix is required. */

            :-ms-input-placeholder { /* IE10–11 */
                color: $color-lavender !important;
                font-size: 0.688em !important;
                left: -5px !important;
                opacity: 1 !important;
                position: relative !important;
            }

            ::-ms-input-placeholder { /* Edge */
                color: $color-lavender;
                font-size: 0.688em;
                left: -5px;
                opacity: 1;
                position: relative;
            }
            /*
            &:focus::-webkit-input-placeholder {
                color: transparent;
            }

            &:focus:-moz-placeholder {
                color: transparent;
            }

            // FF 4-18 //
            &:focus::-moz-placeholder {
                color: transparent;
            }

            // FF 19+ //
            &:focus:-ms-input-placeholder {
                color: transparent;
            }
            */
            // IE 10+ //
            &:active {
                background-color: $color-white;
            }

            &:focus {
                background-color: $color-white;
                color: #000;
                outline: none;

                & + .GN-search-btn {
                    opacity: .5;

                    svg {
                        fill: $color-nyu-purple;
                    }

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .GN-search-btn {
            @include transition(opacity, .3s);

            background: none;
            border: 0;
            bottom: 4px;
            cursor: pointer;
            height: 16px;
            margin: 0;
            padding: 0;
            position: absolute;
            right: 4px;
            top: 22px;
            width: 17px;
            overflow: hidden;

            svg {
                fill: $color-white;
            }
        }

        .GN-search-form {
            // @include transition(top, 0.5s);

            background-color: $color-nyu-darker-purple;
            height: 60px;
            left: 0;
            line-height: 58px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
            display: none;

            .GN-search-text-field-container {
                padding: 17px 15px;
                position: relative;

                input{
                    @include font-perstare("NYUPerstare", 400);

                    background-color: $color-white;
                    box-sizing: border-box;
                    color: #000;
                    outline: none;
                    width: 100%;

                    @include placeholder {
                        left: 0;
                        top: -3px;
                    }
                }

                .GN-search-btn {
                    @include transition(opacity, .5s);

                    right: 20px;
                    opacity: .25;

                    svg {
                        fill: $color-nyu-purple;
                    }

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    #GN-global-nav {
        @include transition(right, 1s);

        background-color: $color-nyu-darker-purple;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        right: -264px;
        top: 0;
        width: 264px;
        z-index: 900;
        height: 100% !important;
        -webkit-overflow-scrolling: scroll;

        &-body {
            display: none;
        }

        #GN-global-nav-nyu-logo {
            line-height: 60px;
            height: 60px;
            padding: 0 15px;

            svg {
                fill: $color-white;
                vertical-align: middle;
                width: 85px;
            }

            a {
                line-height: 60px;
                display: block;
                height: 60px;
                width: 85px;
                padding: 0 15px;
            }
        }

        .GN-nyu-login-btn {
            margin: 30px 15px 40px;

            a {
                @include transitions(background-color .2s, color .2s);

                background: $color-nyu-darker-purple;
                border: 1px solid $color-white;
                color: $color-white;
                display: block;
                font-size: 0.875em;
                height: 35px;
                line-height: 35px;
                min-width: 180px;
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;

                &:hover {
                    background: $color-white;
                    color: $color-nyu-purple;
                }
            }
        }

        .GN-close-btn {
            @include transition(opacity, .5s);

            cursor: pointer;
            height: 18px;
            opacity: .5;
            position: absolute;
            right: 12px;
            text-indent: 100%;
            top: 12px;
            width: 18px;
            z-index: 998;

            &::before, &::after {
                background: $color-white;
                content: "";
                height: 18px;
                left: 8px;
                position: absolute;
                top: 0;
                width: 2px;
            }

            &::before { 
                transform: rotate(45deg);
            }

            &::after { 
                transform: rotate(-45deg);
            }

            &:hover {
                opacity: 1;
            }
        }

        .GN-accordion {
            & > div {
                margin-top: 30px;
            }

            .GN-reveal-sub-nav {
                .GN-accordion-title {
                    @include font-perstare("NYUPerstare", 400);

                    color: $color-white;
                    font-size: 1.5em;

                    &:before {
                        -webkit-transform: translateZ(0) scale(1.0, 1.0) rotate(0);
                                transform: rotate(0);
                    }
                }
                .GN-accordion-body {
                    opacity: 1;
                    max-height: 2000px !important;
                }
            }

            .GN-accordion-title {
                @include font-perstare("NYUPerstare", 300);

                color: $color-lavender;
                cursor: pointer;
                font-size: 1.5em;
                padding: 0 0 0 40px;
                position: relative;

                &:before {
                    @include cheveron-white(.75em,-90deg);
                    @include transitions(left .2s, transform .4s);

                    left: 15px;
                    top: 20%;
                }

                &:hover {
                    color: $color-white;
                }
            }

            .GN-accordion-body {
                @include transitions(opacity .6s, max-height .5s);

                margin-top: 15px;
                max-height: 0;
                opacity: 0.1;
                overflow: hidden;

                a {
                    @include font-perstare("NYUPerstare", 300);

                    color: $color-lavender;
                    display: block;
                    padding: 15px 30px 15px 40px;
                    text-decoration: none;

                    &:hover {
                        color: $color-white;
                    }
                }
            }

            #GN-accordion-locations {
                &.GN-reveal-sub-nav {
                    .GN-accordion {
                        display: block;
                        &-body {
                            display: block;
                        }
                    }
                }
                /*
                .GN-accordion {
                    display: none;
                }
                */

                .GN-accordion-body {
                    display: none;
                    font-size: 1.250em;
                    text-transform: uppercase;

                    .GN-locations-primary {
                        & li:nth-child(1) a{ color: $color-new-york; }
                        & li:nth-child(2) a{ color: $color-abu-dhabi; }
                        & li:nth-child(3) a{ color: $color-shanghai; }

                        & li a:hover{
                            color: $color-white;
                        }
                    }

                    .GN-locations-secondary {
                        font-size: .85em;
                    }

                    a:hover {
                        background: $color-dove-gray;
                    }
                }
            }

            #GN-accordion-schools {
                &.GN-reveal-sub-nav {
                    .GN-accordion-body {
                        display: block;
                    }
                }

                .GN-accordion-body {
                    display: none;
                    a:hover {
                        background: $color-dove-gray;
                    }

                    ul > li > ul a {
                        padding-left: 70px;
                    }
                }
            }
        }
    }

    #GN-local-nav {
        @include transition(left, 1s);

        background-color: $color-white;
        left: -264px;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: 264px;
        z-index: 900;
        -webkit-overflow-scrolling: scroll;

        &-body {
            display: none;
        }
    }
}

// Tablet styles
.GN-mquery-tablet {
    #GN-sub-nav {
        #GN-banner {
            #GN-icons-layer {
                justify-content:space-between;

                #GN-logo {
                    margin-right: auto;
                    margin-left: auto;

                    .GN-logo-full {
                        display: inline-block;
                    }

                    .GN-logo-short {
                        display: none;
                    }
                }

                .GN-icons-right {
                    margin-left: unset;
                }
            }
        }

        #GN-search-form {
            #GN-search-text-field-container {
                float: right;

                input {
                    width: 235px;
                }
            }
        }
    }
}


// Desktop sizes
.GN-mquery-desktop {
    #bypass-nav, #bypass-content {
        display: block;
    }

    #GN-sub-nav {
        #GN-banner {
            #GN-icons-layer {
                justify-content: flex-start;

                #GN-toggle-local-nav {
                    display: none;
                }

                #GN-logo {
                    margin-left: 0;
                    margin-right: auto;
                    padding: 0 15px;

                    .GN-logo-full {
                        display: inline-block;
                    }

                    .GN-logo-short {
                        display: none;
                    }
                }

                .GN-icons-right {
                    #GN-toggle-search-box {
                        display: none;
                    }

                    #GN-search {
                        display: inline-block;
                    }

                    #GN-toggle-global-nav {
                        .GN-btn-txt {
                            display: inline-block;
                            padding-left: 3px;
                        }
                    }
                }
            }

            #GN-search-form {
                display: none;
            }
        }

        #GN-global-nav {
            @include transitions(opacity 1s, transform .5s);

            background-color: transparent;
            left: 50%;
            max-width: 930px;
            opacity: 0;
            position: fixed;
            transform: translateX(-50%) scale(3);
            visibility: hidden;
            width: 100%;
            z-index: 999;
            height: 100% !important;

            .GN-nyu-login-btn {
                margin:0;
                position: absolute;
                right:135px;
                top:30px;
                z-index: 1;
            }

            #GN-global-nav-nyu-logo {
                display: none;
            }

            #GN-accordion {
                #GN-accordion-locations {
                    background: $color-nyu-darker-purple;
                    margin-top: 0;
                    padding: 16px 20px;

                    .GN-accordion-title {
                        display: none;
                    }

                    .GN-accordion-body {
                        margin-top: 0;
                        max-height: unset;
                        opacity: 1;
                        display: block;

                        .GN-locations-primary {
                            display: inline-block !important;
                            width: 32.6%;

                            li {
                                line-height: 1.5;

                                a {
                                    @include transition(padding-left, .2s);

                                    display: inline-block;
                                    font-size: 2.2em;
                                    font-weight: 100;
                                    padding: 0;
                                    position: relative;

                                    &::before {
                                        @include cheveron-white(17px, -90deg);
                                        @include transitions(left .2s, opacity .2s);

                                        left:-25px;
                                        opacity: 0;
                                        top:35%;
                                    }

                                    &:hover {
                                        background: none;
                                        padding-left: 25px;

                                        &::before {
                                            left: 5px;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }

                        .GN-locations-secondary {
                            display: inline-block !important;
                            width: 64%;

                            li {
                                display: inline-block;

                                a {
                                    @include font-perstare("NYUPerstare", 400);

                                    color: $color-blue;
                                    font-size: 0.75em;
                                    padding: 4px 12px 3px 12px;
                                    position: relative;

                                    &::before {
                                        @include cheveron-white(.8em, -90deg);
                                        @include transition(left, .3s);

                                        left: 0;
                                        top:.6em;
                                        opacity: .5;
                                    }

                                    &:hover {
                                        background: none;
                                        color: $color-white;

                                        &::before {
                                            left: 4px;
                                            opacity: 1;
                                        }
                                    }
                                }

                                &:nth-child(even) a {
                                    color: $color-light-blue;

                                    &:hover {
                                        color: $color-white;
                                    }
                                }
                            }
                        }
                    }
                }

                #GN-accordion-schools {
                    display: none;
                    background: $color-nyu-purple;
                    margin-top: 4px;
                    padding: 25px 0;

                    .GN-accordion-title {
                        box-sizing:border-box;
                        color: $color-white;
                        cursor: default;
                        display: inline-block;
                        padding: 0 0 0 25px;
                        vertical-align: top;
                        width: 34%;
                        text-align: left;

                        &::before {
                            content: none;
                        }
                    }

                    .GN-accordion-body {
                        column-count: 2;
                        display: inline-block !important;
                        height: 342px;
                        min-height:352px;
                        margin-top: 0;
                        max-height: unset;
                        opacity: 1;
                        width: 66%;

                        -webkit-column-fill: auto;
                        -moz-column-fill: auto;
                                column-fill: auto;

                        li {
                            -webkit-column-break-inside: avoid;
                                    page-break-inside: avoid;
                                        break-inside: avoid;

                            a {
                                @include transition(padding-left, .2s);

                                color: $color-white;
                                font-size: .85em;
                                font-weight: 300;
                                line-height: 1.25;
                                padding: 8px 0;
                                position: relative;

                                &::before {
                                    @include cheveron-white(.75em, -90deg);
                                    @include transitions(left .2s, opacity .2s);

                                    left:-20px;
                                    opacity:0;
                                    top:.9em;
                                }

                                &:hover {
                                    background:none;
                                    color: $color-school-hover;
                                    padding-left: 20px;

                                    &::before {
                                        left:5px;
                                        opacity: 1;
                                    }
                                }
                            }

                            ul {
                                margin-left: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Mobile/tablet search form visible
.GN-search-form-active {
    overflow: hidden;

    #GN-overlay {
        opacity: 1;
        pointer-events: auto;
    }

    #GN-sub-nav {
        #GN-banner {
            #GN-toggle-search-box {
                background-color: $color-nyu-darker-purple;
                box-sizing: border-box;
            }

            #GN-search-form {
                display: block;
                top: 60px;

                #GN-search-text-field-container {
                    display: block;
                }
            }
        }
    }
}

// Toggled by global-nav.js, mobile menu on right.
.GN-reveal-global{
    overflow: hidden;

    #GN-overlay {
        opacity: 1;
        pointer-events: auto;
    }

    #GN-global-nav-body {
        display: block !important;
    }

    #GN-accordion-body {
        display: block !important;
    }

    #GN-accordion-schools {
        display: block !important;
    }

    #GN-sub-nav {
        #GN-banner {
            #GN-toggle-global-nav {
                background-color: $color-nyu-darker-purple;

                &:hover {
                    border-bottom-color: $color-white;
                }
            }
        }
    }

    &.GN-mquery-phone,
    &.GN-mquery-tablet{
        #GN-sub-nav {
            #GN-banner {
                #GN-icons-layer {
                    left: -264px;
                }
            }

            #GN-global-nav {
                display: block;
                right:0;
            }
        }
    }

    &.GN-mquery-desktop {
        #GN-sub-nav {
            #GN-global-nav {
                opacity: 1;
                transform: translateX(-50%) scale(1);
                visibility: visible;
            }

            #GN-global-nav-body {
                top: 50%;
                transform: translateY(-50%);
                position: relative;
                font-size: 15px;
            }
        }
    }
}

// Toggled by global-nav.js, mobile menu on left. Most styles come from client.
.GN-reveal-local {
    overflow: hidden;

    #GN-overlay {
        opacity: 1;
        pointer-events: auto;
    }

    #GN-sub-nav {
        #GN-banner{
            .GN-toggle-local-nav {
                background-color: $color-nyu-darker-purple;

                &:hover {
                    border-bottom-color: $color-white;
                }
            }
        }
    }

    &.GN-mquery-phone,
    &.GN-mquery-tablet {
        #GN-sub-nav {
            #GN-banner {
                #GN-icons-layer {
                    left: 264px;
                }
            }

            #GN-local-nav {
                display: block;
                left: 0;
                &-body {
                    display: block;
                }
            }
        }
    }
}